(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name people.service:AddToVehicle
   *
   * @description
   *
   */
  angular
    .module('people')
    .service('AddToVehicle', AddToVehicle);

  function AddToVehicle($mdDialog) {
    var vm = this;

    vm.add = add;
    vm.personId = '';

    function add(ev, personId) {
      vm.personId = personId;
      $mdDialog.show({
        controller: AddToVehicleController,
        templateUrl: 'people/add-to-vehicle.tpl.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true
      });
    }

    function AddToVehicleController($scope, $state, $mdDialog, $filter, Restangular) {

      $scope.hide = hide;
      $scope.cancel = cancel;
      $scope.changeEvent = changeEvent;
      $scope.vehiclesDisabled = true;
      $scope.submit = submit;
      $scope.events = [];
      $scope.event = null;
      $scope.existingVehicle = null;

      function cancel() {
        $mdDialog.cancel();
      }

      function hide() {
        $mdDialog.hide();
      }

      Restangular.all('events').getList().then(function (events) {
        $scope.events = events;

        if (events.length > 0) {
          $scope.event = events[0];
          $scope.eventId = events[0].id;
          $scope.meal_cost = $filter('dollars')(events[0].meals);
          $scope.option = $filter('dollars')(events[0].option);
          fetchVehicles(events[0].id);
        }
      });

      function fetchVehicles() {
        if (!$scope.eventId) {
          return;
        }
        $scope.vehiclesDisabled = true;
        Restangular.one('events', $scope.eventId).all('vehicles').getList().then(function (vehicles) {
          $scope.vehicles = vehicles;
          $scope.vehiclesDisabled = false;
        });
      }

      function changeEvent() {
        $scope.existingVehicle = null;
        fetchVehicles();
        for (var i = 0; i < $scope.events.length; i++) {
          if ($scope.events[i].id === $scope.eventId) {
            $scope.event = $scope.events[i];
            $scope.meal_cost = $filter('dollars')($scope.events[i].meals);
            $scope.option = $filter('dollars')($scope.events[i].option);
            break;
          }
        }
      }

      function submit() {
        var f = $scope.form;

        if (f.$invalid) {
          return;
        }

        var data = {
          vehicle_id: $scope.vehicleId,
          event_id: $scope.eventId,
          person_id: vm.personId,
          position: $scope.position,
          meal_cost: $filter('cents')($scope.meal_cost),
          option: $filter('cents')($scope.option),
          participation: parseInt($scope.participation, 10),
          indemnified: $scope.indemnified,
          medical_received: $scope.medical_received,
          vaccinated: $scope.vaccinated,
        };

        $scope.existingVehicle = null;

        Restangular.all('vehicle-people').post(data).then(function () {
          $mdDialog.hide();
          $state.go('events.vehicles.view', {eventId: $scope.eventId, vehicleId: $scope.vehicleId});
        }, function (response) {

          var err = response.data.errors[0];

          if ('vehicle_person:person_on_event' === err.code) {
            $scope.existingVehicle = err.detail.vehicle;
          }
        });
      }
    }

  }
}());
